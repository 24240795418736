/*google font*/
/*@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');
*/

@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@400;500;600;700&display=swap');
/*css vars*/

:root{
  --header-height: 3rem;
  /*Color mode (hue,saturation,lightness)*/
  --hue: 0;
  --sat: 0%;
  --title-color: #d0eed0;
  --title-color-dark: #7bb97b;
  --text-color: #bde6bd;
  --body-color: #000;
  --container-color: rgb(12, 12, 12);

  /*font and typos*/
  --body-font: 'Roboto Mono', monospace;

  --big-font-size:3rem;
  --h1-font-size:2.25rem;
  --h2-font-size: 1.5rem;
  --h3-font-size: 1.25rem;
  --normal-font-size:1rem;
  --small-font-size:0.875rem;
  --smaller-font-size:0.813rem;
  --tiny-font-size: 0.625rem;

  --font-normal:400;
  --font-medium:500;
  --font-semi-bold:600;

  --mb-0-25: 0.25rem;
  --mb-0-5: 0.5rem;
  --mb-0-75: 0.75rem;
  --mb-1: 1rem;
  --mb-1-5: 1.5rem;
  --mb-2: 2rem;
  --mb-2-5: 2.5rem;
  --mb-3: 3rem;

  --z-tooltip: 10;
  --z-fixed:100;
  --z-modal:1000;
}

@media screen and (max-width:992px) {
  :root{
    --big-font-size: 2.75rem;
    --h1-font-size:1.5rem;
    --h2-font-size: 1.25rem;
    --h3-font-size: 1rem;
    --normal-font-size:0.938rem;
    --small-font-size:0.813rem;
    --smaller-font-size:0.75rem;
  }
}


/*base*/
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}


html{
  scroll-behavior: smooth;
}

body,button,input,textarea{
  font-family: var(--body-font);
  font-size: var(--normal-font-size);
}

body{
  background-color: var(--body-color);
  color:var(--text-color);
}
h1,h2,h3 {
  color: var(--title-color);
  font-weight: var(--font-semi-bold);
}
h1,h2 {
  text-shadow: 0 0 6px #00FF00;
}

ul{
  list-style: none;
}

a{
  text-decoration: none;
}

button{
  cursor: pointer;
  border: none;
  outline: none;
}

img{
  max-width: 100%;
  height: auto;
}

video{
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
  position:absolute
}
.section{
  padding: 6rem 0 2rem;
}
.section__title{
  font-size: var(--h1-font-size);
  color: var(--title-color);
}
.section__subtitle{
  display: block;
  font-size: var(--small-font-size);
  margin-bottom: 4rem;

}
.mbt{
  margin-top: 7rem;
}
.section__subtitle2{
  display: block;
  font-size: var(--small-font-size);
  margin-bottom: 0.1rem;
  text-align: center;
}
.section__subtitle3{
  display: block;
  font-size: var(--small-font-size);
  text-align: center;
}

.section__title,.section__subtitle{
  text-align: center;
}

.container{
  max-width: 968px;
  margin-left: auto;
  margin-right: auto;
}
.grid{
  display: grid;
  gap: 1.5rem;
}

.button{
  display: inline-block;
  background-color: var(--title-color);
  color: var(--container-color);
  padding: 1rem 1.4rem;
  border-radius: 1rem;
  font-weight: var(--font-medium);
}
.button:hover{
  background-color: rgb(161, 226, 155);
 
}
 .button__icon{
   margin-left: var(--mb-0-5);
 }
 .button--flex{
   display: inline-flex;
   align-items: center;
 }

@media screen and (max-width:992px){
    .container{
      margin-left: var(--mb-1-5);
      margin-right: var(--mb-1-5);
    }
    .button{
      padding: 1rem 1.75rem;
    }

    .button__icon{
      width: 22px;
      height: 22px;
    }

}
@media screen and (max-width:768px){
  .section{
    padding: 2rem 0 4rem;
  }

}

@media screen and (max-width:350px){
  :root{
    --big-font-size: 2.25rem

  }
  .container{
      margin-left: var(--mb-1);
      margin-right: var(--mb-1);
    }
}