.date__box {
	 position: absolute;
	 display: flex;
	 flex-direction: column;
	 align-items: center;
	 justify-content: center;
	 color: #ccc;
	 border: 4px solid;
	 font-weight: bold;
	 padding: 5px 10px;
}
 .date__box .date__day {
	 font-size: 22px;
}
 .blog-card {
	 padding: 30px;
	 position: relative;
}
 .blog-card .date__box {
	 opacity: 0;
	 transform: scale(0.5);
	 transition: 300ms ease-in-out;
}
 .blog-card .blog-card__background, .blog-card .card__background--layer {
	 z-index: -1;
	 position: absolute;
	 top: 0;
	 left: 0;
	 width: 100%;
	 height: 100%;
}
 .blog-card .blog-card__background {
	 padding: 15px;
	 background: rgb(7, 7, 7);
}
 .blog-card .card__background--wrapper {
	 height: 100%;
	 clip-path: polygon(0 0, 100% 0, 100% 80%, 0 60%);
	 position: relative;
	 overflow: hidden;
}
 .blog-card .card__background--main {
	 height: 100%;
	 position: relative;
	 transition: 300ms ease-in-out;
	 background-repeat: no-repeat;
	 background-size: cover;
}
.img1{
    background-image:url(../../assets/csaw.png);
}
 .blog-card .card__background--layer {
	 z-index: 0;
	 opacity: 0;
	 background: rgba(51, 51, 51, 0.9);
	 transition: 300ms ease-in-out;
}
 .blog-card .blog-card__head {
	 height: 300px;
	 display: flex;
	 align-items: center;
	 justify-content: center;
}
 .blog-card .blog-card__info {
	 z-index: 10;
	 background: rgba(0, 0, 0, 0.9);
	 padding: 20px 15px;
}
 .blog-card .blog-card__info h5 {
	 transition: 300ms ease-in-out;
}
 .blog-card:hover .date__box {
	 opacity: 1;
	 transform: scale(1);
}
 .blog-card:hover .card__background--main {
	 transform: scale(1.2) rotate(5deg);
}
 .blog-card:hover .card__background--layer {
	 opacity: 1;
}
 .blog-card:hover .blog-card__info h5 {
	 color:var(--title-color);
}
 a.icon-link {
	 color: #363738;
	 transition: 200ms ease-in-out;
}
 a.icon-link i {
	 color: var(--title-color);
}
 a.icon-link:hover {
	 color: var(--title-color);
	 text-decoration: none;
}
 