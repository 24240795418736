.home__content{
    grid-template-columns: 116px repeat(2,1fr);
    padding-top: 2.5rem;
    column-gap: 2rem;
    align-items: center;
}
.home__container{
    row-gap: 7rem;
}

.home__scroll{
    margin-left: 9.25rem;
}

.wheel{
    animation: scroll 2s ease infinite;
}

@keyframes scroll{
    0%{
        transform: translateY(0);

    }
    30%{
        transform: translateY(3.75rem);
    }
}

.home__scroll-name{
    color: var(--title-color);
    font-weight: var(--font-medium);
    margin-right: var(--mb-0-25);
    margin-left: 2px;
}

.home__scroll-arrow{
    font-size: 1.25rem;
    color: var(--title-color);
}

.home__img{
    background: url(../../assets/profile.jpg);
    filter: grayscale(90%);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    box-shadow: inset 0px 0px 0px 5px var(--text-color);
    order: 1;
    justify-self: center;
    width: 300px;
    height: 300px;
    animation:profile__animate 8s ease-in-out infinite 0s;
}
@keyframes profile__animate{
    0%{
        border-radius: 60% 40% 30% 70%/ 60% 30% 70% 40%;
    }
    50%{
        border-radius: 30% 60% 70% 40%/ 50% 60% 30% 60%;
    }
    100%{
        border-radius: 60% 40% 30% 70%/ 60% 30% 70% 40%;
    }
}

.home__social{
    display: grid;
    grid-template-columns: max-content;
    row-gap: 1rem;
}
.home__social-icon:hover{
    color:var(--title-color-dark);
}

.home__social-icon{
    font-size: 1.25rem;
    color: var(--title-color);
}

.home__title{
    font-size: var(--big-font-size);
    margin-bottom: var(--mb-0-25);
}
.home__hand{
    width: 38px;
    height: 38px;
    margin-left: 0.4rem;
}

.home__subtitle{
    margin-bottom: 0.2rem;
    font-size: 1.2rem;
    font-weight: 700;
    color:var(--title-color);
    max-width: 400px;
   /* text-shadow: 0 0 4px var(--title-color);*/
}


.home__description{
    max-width: 400px;
    margin-bottom: var(--mb-3);
    
}

@media screen and (max-width: 992px){
    .home__content{
        grid-template-columns: 100px repeat(2,1fr);
        column-gap: 1.25rem;

    }
    .home__subtitle{
        margin-bottom:0.3rem;
    }
    .home__subtitle::before{
        width: 42px;
        top: 0.8rem;
    }
    .home__description{
        max-width: initial;
        margin-bottom: var(--mb-2-5);
    }

    .home__img{
        width: 250px;
        height: 250px;
        box-shadow: inset 0 0 0 8px rgb(255 255 255 / 30%);
    }
    .home__scroll{
        margin-left: 7.5rem;

    }

}

a{
    font-style: italic;
    color: var(--text-color);
    text-shadow: 0 0 2px var(--text-color);
}
@media screen and (max-width: 482px){
    .home__subtitle{
        font-size: 0.85rem;
    }
    .home__description{
        font-size: 0.78rem;
    }
}
@media screen and (max-width: 768px){
    .home__content{
        justify-self:center;
        grid-template-columns: 0.5fr 3fr;
        padding-top: 3.5rem;        
    }
    .home__img{
        order: initial;
        justify-self:center;
        box-shadow: inset 0 0 0 6px rgb(255 255 255 / 30%);
        width: 200px;
        height: 200px;
    }
    .home__data{
        grid-column: 1/3;
    }  
    .home__scroll{
        display: none;
    }  
}


@media screen and (max-width: 350px){
    .home__img{
        width: 180px;
        height: 180px;
    }
    .home__description{
        font-size: 0.68rem;
    }
    .home__subtitle{
        font-size: 0.7rem;
    }

}

.line-1{
    border-right:10px solid rgba(255,255,255,.75);
    white-space:nowrap ;
    overflow: hidden;
}
.btn{
    display:flex;
    justify-self: left;
    padding-top: 2rem;
    padding-left: 4rem;
}
/* Animation */
.anim-typewriter{
  animation: typewriter 1.6s steps(11) 0.5s 1 normal both,
             blinkTextCursor 500ms 0s steps(44) 20,
             blinkTextCursor1 500ms 1.9s steps(44) infinite;
}
p:nth-child(1){
    border-right:5px solid rgba(255,255,255,.75);
    white-space:nowrap ;
    overflow: hidden;
    animation: typewriter 1.25s steps(11) 3.6s 1 normal both,
    blinkTextCursor1 500ms 0s steps(44) infinite,
    blinkTextCursor 500ms 2.3s steps(44) 20,
    blinkTextCursor1 500ms 5s steps(44) infinite;
}
p:nth-child(2){
    border-right:5px solid rgba(255,255,255,.75);
    white-space:nowrap ;
    overflow: hidden;
    animation: typewriter 1.25s steps(11) 5.5s 1 normal both,
    blinkTextCursor1 500ms 0s steps(44) infinite,
    blinkTextCursor 500ms 5s steps(44) 20,
    blinkTextCursor1 500ms 7s steps(44) infinite;
}
p:nth-child(3){
    border-right:5px solid rgba(255,255,255,.75);
    white-space:nowrap ;
    overflow: hidden;
    animation: typewriter 1.25s steps(11) 7.5s 1 normal both,
    blinkTextCursor1 500ms 0s steps(44) infinite,
    blinkTextCursor 500ms 7s steps(44) 30,
    blinkTextCursor1 500ms 11s steps(44) infinite;
}

@keyframes typewriter{
  from{width: 0;}
  to{width: 100%;}
}
@keyframes blinkTextCursor{
  from{border-right-color: rgba(255,255,255,.75);}
  to{border-right-color: transparent;}
}
@keyframes blinkTextCursor1{
  from{border-right-color: transparent;}
  to{border-right-color: transparent;}
}

.trans{
  transform: scale(0.94);
  animation: scale 3s forwards cubic-bezier(0.5, 1, 0.89, 1);
}
@keyframes scale {
  100% {
    transform: scale(1);
  }
}
.fadein{
    opacity: 0;
    filter: blur(4px);
    animation: fade-in 0.8s 11s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
.fadein2{
    opacity: 0;
    animation: fade-in 0.8s 20s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

@keyframes fade-in {
  100% {
    opacity: 1;
    filter: blur(0);
  }
}
